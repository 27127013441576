import request from '@/untils/request'
import QS from 'qs'
import base_ from '@/untils/base';
/**
 * post方法，对应post请求
 * @desc注册请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */

export const Upload = {

    uploadLitpic: function(data) {
        return request({
            url: `${base_.url}/wiki/upload_cos_litpic`,
            method: 'post',
            data: data,
            showloading: true,
        })
    },
    litpic_del: function(data) {
        return request({
            url: `${base_.url}/wiki/litpic_del`,
            method: 'post',
            data: QS.stringify(data),
            showloading: true,
        })
    },

    uploadImg_cos: function(data) {
        return request({
            url: `${base_.url}/wiki/upload_cos`,
            method: 'post',
            data: data,
        })
    },

    uploadImg_cos_del: function(data) {
        return request({
            url: `${base_.url}/wiki/upload_cos_del`,
            method: 'post',
            data: QS.stringify(data),
            showloading: true,
        })
    },

}