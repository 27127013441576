<template>
    <div class="main detail">
        <my-header :title="dataDetail.title?dataDetail.title:'加载中...'" :class="[{'full':dataDetail.litpic_obj!=0}]"></my-header>
        <van-skeleton title :row="20" :loading="loading" v-if="!error">
            <div class="center upload litpic_box">
                <van-empty v-if="dataDetail.litpic_obj==0" image="error" description="还未上传封面">
                    <van-button round type="success" icon="photograph" @click="$refs.litpic_uploader.chooseFile();" class="bottom-button">立即上传</van-button>
                </van-empty>
                <van-uploader :class="[{'litpic':dataDetail.litpic_obj!=0}]" ref="litpic_uploader" :preview-image="true" lazy-load :max-count="1" preview-size="100%" :max-size="5000*1024" @oversize="onOversize" v-model="dataDetail.litpic_obj" :before-delete="photoDelConfirm" @delete="litpicDel" :after-read="litpicUpload">
                </van-uploader>
            </div>
            <van-swipe-cell class="info" :stop-propagation="true">
                <van-cell>
                    <b @click="copy(dataDetail.title)">{{dataDetail.title}}</b>
                    <span class="cname" @click="copy(dataDetail.cname)" v-if="dataDetail.cname">{{dataDetail.cname}}</span>
                    <span class="rank" @click="copy(dataDetail.rank)" v-if="dataDetail.rank">{{dataDetail.rank}}</span>
                    <span v-if="dataDetail.price>0" class="price fr" @click="copy(dataDetail.typename+' '+dataDetail.title+' '+dataDetail.price+'元/'+dataDetail.price_unit)">￥{{dataDetail.price}}<i>/{{dataDetail.price_unit}}</i></span>
                </van-cell>
                <template #right>
                    <van-button type="primary" square @click="$router.push('/wiki/edit/'+dataDetail.id)">编辑</van-button>
                    <van-button type="success" v-if="dataDetail.status==-1" square @click="recovery()">还原</van-button>
                    <van-button type="danger" v-if="dataDetail.status==-1" square @click="clear()">清除</van-button>
                    <van-button type="danger" v-else square @click="del()">删除</van-button>
                </template>
            </van-swipe-cell>
            <van-swipe-cell class="tags">
                <van-cell>
                    <span class="tag">
                        <van-tag size="large" v-if="dataDetail.typename" type="success" @click="copy(dataDetail.typename)">{{dataDetail.typename}}</van-tag>
                    </span>
                    <span class="tag" v-for="(item,i) in dataDetail.tese" :key="'type'+i">
                        <van-tag size="large" v-if="item>0" type="success" @click="copy(getTextById(conf.teses,item,'id'))">{{getTextById(conf.teses,item,'id')}}</van-tag>
                    </span>
                    <span class="tag" v-if="dataDetail.petal">
                        <van-tag size="large" type="success" @click="copy(dataDetail.petalText)">{{dataDetail.petalText}}</van-tag>
                    </span>
                    <span class="tag" v-if="dataDetail.tongue">
                        <van-tag size="large" type="success" @click="copy(dataDetail.tongueText)">{{dataDetail.tongueText}}</van-tag>
                    </span>
                    <span class="tag" v-if="dataDetail.side">
                        <van-tag size="large" type="success" @click="copy(dataDetail.sideText)">{{dataDetail.sideText}}</van-tag>
                    </span>
                </van-cell>
            </van-swipe-cell>
            <van-collapse v-model="dataDetail.activeNollapse" v-if="dataDetail.description">
                <van-collapse-item title="更多描述" icon="description" name="1">
                    <div @click="copy(dataDetail.description)">{{dataDetail.description}}</div>
                </van-collapse-item>
            </van-collapse>
            <van-divider v-else />
            <div class="btns">
                <van-tag type="success" size="large" @click="$router.push('/wiki/photo/'+dataDetail.id)">
                    <van-icon name="photo-o" />相册({{dataDetail.photos.length}})</van-tag>
                <van-tag type="success" size="large" @click="showMore(false)">
                    <van-icon name="search" />百度搜索</van-tag>
                <van-tag type="success" size="large" @click="showMore(dataDetail.url)">
                    <van-icon name="share-o" />打开百科</van-tag>
                <van-tag type="success" size="large" @click="price_add(dataDetail.id)">
                    <van-icon name="plus" />添加报价</van-tag>
            </div>
            <van-divider />
            <div class="list_price">
                <van-swipe-cell v-for="(item,i) in dataDetail.list_price" :key="item.id">
                    <van-cell>
                        <template #title>
                            <span class="custom-title">{{item.title}}</span>
                            <span class="uptime fr">{{item.uptime}}</span>
                            <span :class="['price','pubprice','fr',{'transparent':!item.pubprice}]">￥{{item.pubprice}}</span>
                            <span :class="['price','fr',{'transparent':item.inprice==0}]">￥{{item.inprice}}</span>
                            <p class="content">{{item.content}}</p>
                        </template>
                    </van-cell>
                    <template #right>
                        <van-button square type="primary" @click="price_edit(i)" text="编辑" />
                        <van-button square type="danger" @click="price_del(item.id)" text="删除" />
                    </template>
                </van-swipe-cell>
            </div>
        </van-skeleton>
    </div>
    <van-popup v-model:show="price_data.show" position="bottom" round closeable :style="{ height: '18rem',width: '100%' }" teleport="body">
        <van-form @submit="price_edit_save" class="padding-top-large">
            <van-field readonly clickable label="供应商" v-model="price_data.data.title" placeholder="选择供应商" @click="price_data.supplier_show = true" />
            <van-popup v-model:show="price_data.supplier_show" round position="bottom" teleport="body">
                <van-picker :columns="conf.suppliers" :columns-field-names="customFieldName" title="选择供应商" show-toolbar @confirm="supplierOnConfirm" @cancel="price_data.supplier_show = false" />
            </van-popup>
            <van-field v-model="price_data.data.inprice" label="内部价格" placeholder="￥" />
            <van-field v-model="price_data.data.pubprice" label="公开价格" placeholder="￥" />
            <van-field v-model="price_data.data.content" label="备注" placeholder="价格备注" />
            <div class="padding">
                <van-button block type="success" round native-type="submit">
                    {{price_data.data.id?'更新价格':'添加价格'}}
                </van-button>
            </div>
        </van-form>
    </van-popup>
</template>
<script>
import { Wiki } from "@/api/wiki.js";
import { Upload } from "@/api/upload.js";
import { Picker, Checkbox, CheckboxGroup, Dialog } from 'vant';
export default {
    components: {
        [Wiki.name]: Wiki,
        [Picker.name]: Picker,
    },
    data() {
        return {
            loading: true,
            error: false,
            dataDetail: { id: this.$route.params.id, photos: [], list_price: [], litpic_obj: [], activeNollapse: [0] },
            price_data: { show: false, supplier_show: false, data: { supplier: 0 } },
            customFieldName: { text: 'name', value: 'id' },
            conf:this.$store.get('conf')
        };
    },
    created: function() { this.getDetail(); },
    methods: {
        //获取详情数据
        getDetail() {
            Wiki.detail({ 'id': this.dataDetail.id, 'showloading': true })
                .then(res => {
                    this.loading = false;
                    if (res && res.code == 1) {
                        this.dataDetail.litpic_obj = res.data.thumb ? [{ url: res.data.thumb }] : [];
                        this.dataDetail.title = res.data.title;
                        this.dataDetail.list_price = res.data.list_price;
                        this.dataDetail.photos = res.data.photos;
                        this.dataDetail.cname = res.data.cname;
                        this.dataDetail.price = res.data.price;
                        this.dataDetail.status = res.data.status;
                        this.dataDetail.price_unit = res.data.price_unit;
                        this.dataDetail.pubprice = res.data.pubprice;
                        this.dataDetail.inprice = res.data.inprice;
                        this.dataDetail.typeid = res.data.typeid;
                        this.dataDetail.typename = this.getCnameBytypeid(this.dataDetail.typeid);
                        this.dataDetail.rank = res.data.rank;
                        this.dataDetail.description = res.data.description;
                        this.dataDetail.tese = res.data.tese;
                        this.dataDetail.typeText = this.getTextByIds(this.conf.teses, this.dataDetail.type, 'id');
                        this.dataDetail.petal = res.data.petal;
                        this.dataDetail.petalText = this.getTextById(this.conf.petals, this.dataDetail.petal, 'id');
                        this.dataDetail.tongue = res.data.tongue;
                        this.dataDetail.tongueText = this.getTextById(this.conf.tongues, this.dataDetail.tongue, 'id');
                        this.dataDetail.side = res.data.side;
                        this.dataDetail.sideText = this.getTextById(this.conf.sides, this.dataDetail.side, 'id');
                        this.dataDetail.activeNollapse = [0];
                        this.dataDetail.url = res.data.url;
                    } else {
                        this.error = true;
                        this.$router.push('/wiki/search');
                    }
                })
        },
        //上传文件过大
        onOversize() {
            this.$toast('文件大小超过限制');
        },
        //是否删除相册
        photoDelConfirm() {
            return this.$dialog.confirm({
                name: '确定删除吗',
                message: '删除后不可以恢复',
            })
        },
        //删除缩略图
        litpicDel() {
            Upload.litpic_del({ id: this.dataDetail.id }).then(res => {
                if (res.code == 1) {
                    //this.getDetail();
                    return true;
                }
            }).catch(() => {
                return false;
            });
        },
        //上传缩略图
        litpicUpload(file) {
            var param = new FormData();
            param.append("file", file.file);
            param.append("id", this.dataDetail.id);
            Upload.uploadLitpic(param).then(res => {
                if (res.code != 1) {
                    this.dataDetail.litpic_obj.pop();
                }
            }).catch(() => {
                this.dataDetail.litpic_obj.pop();
            });
        },
        //编辑进价
        price_edit(index) {
            this.price_data.data = JSON.parse(JSON.stringify(this.dataDetail.list_price[index]));
            //this.price_data.data.pubprice=this.price_data.data.pubprice==0?'':this.price_data.data.pubprice;
            this.price_data.show = true;
        },
        //添加进价
        price_add(aid) {
            this.price_data.data = { aid: aid, title: this.conf.suppliers[5].name, sid: this.conf.suppliers[5].id, content: '' };
            this.price_data.show = true;
        },
        //删除进价
        price_del(id) {
            this.$dialog.confirm({
                    title: '确定删除吗',
                    message: '删除后不可以恢复',
                })
                .then(() => {
                    Wiki.price_del({ 'id': id }).then(res => {
                        this.$toast.success(res.msg);
                        this.getDetail();
                    });
                }).catch(() => {

                });
        },
        //进价保存
        price_edit_save() {
            if (this.price_data.data['id'] > 0) {

                Wiki.price_edit({ 'id': this.price_data.data['id'], 'sid': this.price_data.data['sid'], 'inprice': this.price_data.data['inprice'], 'pubprice': this.price_data.data['pubprice'], 'content': this.price_data.data['content'], })
                    .then(res => {
                        this.price_data.show = false;
                        this.$toast.success(res.msg);
                        this.getDetail();
                    })
            } else {
                Wiki.price_add({ 'aid': this.price_data.data['aid'], 'sid': this.price_data.data['sid'], 'inprice': this.price_data.data['inprice'], 'pubprice': this.price_data.data['pubprice'], 'content': this.price_data.data['content'], })
                    .then(res => {
                        this.price_data.show = false;
                        this.$toast.success(res.msg);
                        this.getDetail();
                    })
            }
        },
        //选择供应商
        supplierOnConfirm(value) {
            this.price_data.supplier_show = false;
            this.price_data.data.title = value['name'];
            this.price_data.data.sid = value['id'];
        },
        //删除品种，只标记，可在后台恢复
        del() {
            this.$dialog.confirm({
                    title: '提醒',
                    message: '确定删除到回收站吗',
                })
                .then(() => {
                    Wiki.del({ 'id': this.dataDetail.id }).then(res => {
                        this.$toast.success(res.msg);
                        var that = this;
                        setTimeout(function() {
                            that.$router.push('/wiki/search');
                        }, 1000);
                    });
                }).catch(() => {

                });
        },
        //还原
        recovery() {
            Wiki.recovery({ 'id': this.dataDetail.id })
                .then(res => {
                    this.$toast.success(res.msg);
                    this.getDetail();
                }).catch(() => {
                });

        },
        //清除
        clear() {
            if(this.dataDetail.thumb){
                this.$toast('清除前请先删除缩略图');
                return false;
            }
            if(this.dataDetail.photos.length>0){
                this.$toast('清除前请先清空相册');
                return false;
            }

            this.$dialog.confirm({
                    title: '提醒',
                    message: '清除后不可恢复',
                })
                .then(() => {
                    Wiki.clear({ 'id': this.dataDetail.id }).then(res => {
                        this.$toast.success(res.msg);
                        var that = this;
                        setTimeout(function() {
                            that.$router.push('/wiki/recycle');
                        }, 1000);
                    });
                }).catch(() => {
                });
        },
        //打开外部搜索
        showMore(url) {
            let openurl = url ? url : 'https://m.baidu.com/sf/vsearch?pd=image_content&tn=vsearch&atn=page&word=兰花' + this.dataDetail.title;
            let iTop = 140;
            let iHeight = window.screen.height - iTop;
            let iWidth = 375;
            let iLeft = 0;
            window.open(openurl, 'newwindow', 'height=' + iHeight + ', width=' + iWidth + ', top=' + iTop + ', left=' + iLeft + ', toolbar=no, menubar=no, scrollbars=no, resizable=no,location=no, status=no');
        },
        //通过ids值获取文字
        getTextByIds(arr, ids, key) {
            if (!Array.isArray(ids)) {
                return '';
            }
            var text_arr = [];
            for (var i = 0; i < arr.length; i++) {
                if (ids.includes(arr[i][key])) {
                    text_arr.push(arr[i].name);
                }
            }
            return text_arr.join(',');
        },
        //通过值获取索引
        getIndexByVal(arr, val, key) {
            var r = 0;
            for (var i = 0; i < arr.length; i++) {
                if (arr[i][key] == val) {
                    r = i;
                    break;
                }
            }
            return r;
        },
        //通过id值获取文字
        getTextById(arr, id, key) {
            if (id <= 0 || id >= 99) {
                return '';
            }
            var text = '';
            for (var i = 0; i < arr.length; i++) {
                if (id == arr[i][key]) {
                    text = arr[i].name;
                    break;
                }
            }
            return text;
        },
        //通过分类ID获取分类别名
        getCnameBytypeid(typeid) {
            let types = this.conf.types;
            for (let i in types) {
                if (typeid == types[i]['id']) {
                    return types[i]['name'];
                }
            }
            return false;
        },
        //复制文本
        copy(text) {
            this.$copyText(text);
            this.$toast('已复制');
        },
    },
    computed: {

        //显示详细信息

    },
    watch: {}
};
</script>
<style lang="less">
</style>